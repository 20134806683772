<template>
    <div class="report_basic" v-loading="state.loading">
        <div class="container">
            <small-title tit="新建报告"></small-title>
            <el-divider />
            <el-form ref="formDom" :model="state.form" :rules="state.rules" label-position="left" label-width="90px">
                <el-form-item label="报告名称" prop="name">
                    <el-input v-model="state.form.name" style="width:50%" />
                </el-form-item>
                <el-form-item label="报告描述" prop="remarks">
                    <el-input v-model="state.form.remarks" type="textarea" style="width:50%" />
                </el-form-item>
                <el-form-item label="报告类型" prop="reportType">
                    <el-radio-group v-model="state.form.reportType" :disabled="!!state.id">
                        <div class="type_box">
                            <div class="li" v-for="item in state.typeList" :key="item.name">
                                <el-radio :label="item.value">{{ item.name }}</el-radio>
                                <!-- <el-tooltip class="box-item" effect="dark" content="点击放大预览" placement="right">
                                    <div class="img_box">
                                        <el-image :src="item.imgUrl" fit="cover" :preview-src-list="[item.imgUrl]">
                                        </el-image>
                                    </div>
                                </el-tooltip> -->
                            </div>
                        </div>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="报告模板" prop="outcomeTemplate">
                    <el-radio-group v-model="state.form.outcomeTemplate" :disabled="!!state.id">
                        <ul>
                            <li v-for="item in reportTempStore.tempList" :key="item.id">
                                <el-radio :label="item.id">{{ item.name }}</el-radio>
                                <div class="temp_desc">
                                    <p>报告描述：</p>
                                    <p v-html="item.desc"></p>
                                </div>
                            </li>
                        </ul>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit(formDom)">确定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import logo from '@/assets/img/logo.png';
import { useRouter, useRoute } from 'vue-router';
import SmallTitle from '@/components/common/SmallTitle.vue';
import { reportGetInfo, reportSave, reportHisSave } from '@/request/dishApi';
import useReportTempStore from '@/store/reportTemp.js'

const $router = useRouter();
const $route = useRoute();

const reportTempStore = useReportTempStore()// 报告模板pinia

const state = reactive({
    loading: false,
    id: $route.query.id,
    typeList: [{ name: '热卖推荐菜', value: 'TOP', imgUrl: logo }, { name: '趋势增长菜', value: 'UP', imgUrl: logo }],
    form: {
        name: '',
        remarks: '',
        reportType: 'TOP',
        outcomeTemplate: reportTempStore.tempList[0].id
    },
    rules: {
        name: [{ required: true, message: '请输入报告名称', trigger: 'blur' }],
        remarks: [{ required: true, message: '请输入报告描述', trigger: 'blur' }],
        reportType: [{ required: true, message: '请选择报告类型', trigger: 'change' }],
        outcomeTemplate: [{ required: true, message: '请选择报告模板', trigger: 'change' }],
    }
})
const formDom = ref(null);

// 从历史报告生成新报告时，获取历史报告的信息并回显
async function getHistoryInfo() {
    const { data } = await reportGetInfo({ id: state.id })
    state.form.name = data?.name || '';
    state.form.remarks = data?.remarks || '';
    state.form.reportType = data?.reportType || 'TOP';
    state.form.outcomeTemplate = data?.outcomeTemplate || 'MODE_ONE';
}

// 提交验证
function onSubmit(formEl) {
    if (!formEl) return
    formEl.validate((valid) => {
        if (valid) {
            if (state.id) {
                addFromHistory()//新建报告-从历史报告新建
            } else {
                addNewFn()//新建报告-全新
            }
        }
    })
}

// 新建报告-全新
async function addNewFn() {
    state.loading = true;
    const { code, data } = await reportSave(state.form)
    state.loading = false;
    if (code === '0') {
        toReportPageOne(data)
    }
}

// 新建报告-从历史报告新建
async function addFromHistory() {
    state.loading = true;
    const params = { name: state.form.name, remarks: state.form.remarks, reportId: state.id }
    const { code, data } = await reportHisSave(params)
    state.loading = false;
    if (code === '0') {
        toReportPageOne(data)
    }
}

// 跳转报告第一页
function toReportPageOne(id) {
    $router.push({ path: '/dishsearch/generatereport', query: { page: 1, id: id } });
}

onMounted(() => {
    $route.query.id && getHistoryInfo()
})

</script>

<style lang="scss" scoped>
.report_basic {
    padding: 20px 40px;

    .container {
        background-color: #fff;
        padding: 20px;
        border-radius: 14px;

        .type_box {
            display: flex;

            .li {
                margin-right: 60px;

                .el-radio {
                    margin-right: 8px;
                }

                .img_box {
                    margin-left: 22px;
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                    border-radius: 5px;
                    position: relative;
                    background-color: #f2f2f2;

                    .el-image {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .temp_desc {
            padding-left: 24px;

            p {
                font-size: 12px;
                color: #999;
                line-height: 20px;
            }
        }
    }
}
</style>