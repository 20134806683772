import { defineStore } from 'pinia'

export default defineStore('reportTempStore', {
    state: () => ({
        // 报告模板选项，不同的报告模板会跳转不同的配置和结果页面
        tempList: [
            {
                id: 'MODE_ONE',
                name: '图片集+区域对比报告',
                desc: '全国热卖推荐菜TOP20图片集，<br />目标区域与其它对比区域TOP20对比。',
                configPath: '/dishsearch/reportconfig1',
                resultPath: '/dishsearch/reportresult1'
            }
        ]
    })
})